import React, { useState, useEffect } from 'react';
import { auth } from './firebaseConfig';
import './AccountData.css';

const AccountData = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const user = auth.currentUser;
      if (!user) {
        setError('User not authenticated');
        return;
      }

      const firebaseuid = user.uid;
      const cachedData = localStorage.getItem('userData');

      if (cachedData) {
        setData(JSON.parse(cachedData));
      }

      try {
        const response = await fetch('https://mlplh9oe1g.execute-api.eu-north-1.amazonaws.com/V1/User/Data', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ firebaseuid })
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        setData(result);
        localStorage.setItem('userData', JSON.stringify(result));
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 60000); // Update data every 60 seconds

    return () => clearInterval(interval);
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div className="account-data-container">
      <h1>MY ACCOUNT</h1>
      <div className="data-grid">
        <div className="data-section">
          <h2>Account</h2>
          <p><strong>Username:</strong> {data?.user?.username ?? 'N/A'}</p>
          <p><strong>Language:</strong> {data?.user?.language ?? 'N/A'}</p>
          <p><strong>Email:</strong> {data?.user?.email ?? 'N/A'}</p>
          <p><strong>Account Creation Date:</strong> {data?.user?.account_creation_date ? new Date(data.user.account_creation_date).toLocaleString() : 'N/A'}</p>
        </div>
        <div className="data-section">
          <h2>Pet</h2>
          <p><strong>Level:</strong> {data?.pets?.level ?? 'N/A'}</p>
          <p><strong>XP:</strong> {data?.pets?.xp ?? 'N/A'}</p>
          <p><strong>Hunger:</strong> {data?.pets?.hunger ?? 'N/A'}</p>
          <p><strong>Happiness:</strong> {data?.pets?.happiness ?? 'N/A'}</p>
          <p><strong>Name:</strong> {data?.pets?.name ?? 'N/A'}</p>
          <p><strong>Next Level XP Requirement:</strong> {data?.pets?.nextLevelXPReq ?? 'N/A'}</p>
        </div>
        <div className="data-section">
          <h2>Balances</h2>
          <p><strong>Paw coins:</strong> {data?.balance?.amount ?? 'N/A'}</p>
        </div>
        <div className="data-section">
          <h2>Subscription</h2>
          <p><strong>Expiration Date:</strong> {data?.activeSubscription?.expiration ? new Date(data.activeSubscription.expiration).toLocaleString() : 'N/A'}</p>
        </div>
      </div>
    </div>
  );
};

export default AccountData;
