// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, OAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBQyb3ZOf29NACmiWavxMV8THeE7SZWva0",
  authDomain: "audiocat-cf6f4.firebaseapp.com",
  projectId: "audiocat-cf6f4",
  storageBucket: "audiocat-cf6f4.appspot.com",
  messagingSenderId: "662740365804",
  appId: "1:662740365804:web:aaa0e6c2e9f4d0d8ad50c9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider('apple.com'); // Add Apple provider

export { auth, app, googleProvider, appleProvider }; // Export appleProvider
