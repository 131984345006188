import React, { useState, useEffect } from 'react';
import { signOut } from "firebase/auth";
import { auth } from './firebaseConfig';
import SendData from './SendData';
import Subscriptions from './Subscriptions';
import DangerZone from './DangerZone';
import AccountData from './AccountData';
import './Home.css';

const Home = ({ onLogout }) => {
  const [currentView, setCurrentView] = useState('accountData');
  const [user, setUser] = useState(null);
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        setUser({
          uid: currentUser.uid,
          provider: currentUser.providerData[0]?.providerId || 'Unknown'
        });
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    signOut(auth);
    onLogout();
  };

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const renderContent = () => {
    switch (currentView) {
      case 'account':
        return (
          <div className="home-container">
            <h1>DELETE ACCOUNT</h1>
            <p>WARNING! DELETING YOUR ACCOUNT IS A PERMANENT ACTION AND CANNOT BE UNDONE!</p>
            <DangerZone onLogout={onLogout} />
          </div>
        );
      case 'pricing':
        return (
          <div className="home-container">
            <Subscriptions firebaseuid={user?.uid} />
          </div>
        );
      case 'accountData':
        return (
          <div className="home-container">
            <AccountData />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="layout">
      <aside className={`sidebar ${isSidebarOpen ? 'open' : ''}`} id="sidebar">
        <div className="sidebar-header">
          <h2>AudioCat</h2>
        </div>
        <nav>
          <ul>
            <li><button onClick={() => setCurrentView('accountData')}>My Account</button></li>
            <li><button onClick={() => setCurrentView('account')}>Delete Account</button></li>
            <li><button onClick={() => setCurrentView('pricing')}>Subscription</button></li>
          </ul>
        </nav>
        <button onClick={handleLogout} className="btn btn-logout">Logout</button>
        {user && (
          <div className="user-info">
            <p>FUID: {user.uid}</p>
            <p>Auth Method: {user.provider}</p>
          </div>
        )}
      </aside>
      <button
        className="sidebar-toggle"
        onClick={toggleSidebar}
        aria-label="Toggle sidebar"
        aria-controls="sidebar"
        aria-expanded={isSidebarOpen}
      >
        ☰
      </button>
      <main className="content">
        {renderContent()}
      </main>
    </div>
  );
};

export default Home;
