import React, { useState } from 'react';
import { deleteUser } from "firebase/auth";
import { auth } from './firebaseConfig';
import './DangerZone.css';

const DangerZone = ({ onLogout }) => {
  const [showConfirm, setShowConfirm] = useState(false);

  const handleDeleteAccount = async () => {
    const user = auth.currentUser;
    if (!user) return;

    setShowConfirm(false);

    try {
      await deleteUser(user);
      const response = await fetch('https://04l1masgwj.execute-api.eu-north-1.amazonaws.com/v1/DELETEACCOUNT', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ firebaseuid: user.uid })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      console.log('Account deleted successfully');
      onLogout();
    } catch (error) {
      console.error('Error removing user:', error);
    }
  };

  return (
    <div className="danger-zone">
      <h2>DANGER ZONE</h2>
      <button onClick={() => setShowConfirm(true)} className="btn btn-delete">Delete My Account</button>
      {showConfirm && (
        <div className="modal">
          <div className="modal-content">
            <h2>Confirm Account Deletion</h2>
            <p>Are you sure you want to delete your account? This action cannot be undone.</p>
            <div className="modal-buttons">
              <button onClick={handleDeleteAccount} className="btn btn-confirm-delete">Yes, Delete</button>
              <button onClick={() => setShowConfirm(false)} className="btn btn-cancel">Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DangerZone;
