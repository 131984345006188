import React, { useState } from 'react';
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { auth, googleProvider, appleProvider } from './firebaseConfig'; // Import appleProvider
import './Login.css'; // Import CSS file for styling

const Login = ({ onLogin }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = async () => {
        try {
            await signInWithEmailAndPassword(auth, email, password);
            onLogin();
        } catch (err) {
            setError(err.message);
        }
    };

    const handleGoogleSignIn = async () => {
        try {
            await signInWithPopup(auth, googleProvider);
            onLogin();
        } catch (err) {
            setError(err.message);
        }
    };

    const handleAppleSignIn = async () => {
        try {
            await signInWithPopup(auth, appleProvider);
            onLogin();
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <div className="login-container">
            <input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" />
            <input type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Password" />
            <button onClick={handleLogin}>Login</button>
            <button onClick={handleGoogleSignIn} className="google-sign-in-button">
                <img src="Google.png" alt="Google logo" /> Sign in with Google
            </button>
            <button onClick={handleAppleSignIn} className="apple-sign-in-button">
                <img src="Apple.png" alt="Apple logo" /> Sign in with Apple
            </button>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <p className="login-info">
                By logging in, you reserve the option to terminate your account at any moment. All associated data will be eradicated from our records promptly. Note, however, that data pertaining to subscriptions will be retained for a period of ninety days post-cancellation to accommodate any subsequent inquiries.
            </p>
        </div>
    );
};

export default Login;
