// RevenueCat.js
import { Purchases } from '@revenuecat/purchases-js';

let purchasesInstance = null;

export const initializeRevenueCat = (firebaseuid) => {
  if (!purchasesInstance) {
    console.log('Initializing RevenueCat with API key and app user ID:', firebaseuid);
    Purchases.configure('rcb_lyIuXyeWkKyTkibTciJhwfMDOFVJ', firebaseuid);
    purchasesInstance = Purchases.getSharedInstance();
  }
};

export const getOfferings = async () => {
  if (!purchasesInstance) throw new Error("Purchases instance is not initialized.");
  console.log('Fetching offerings...');
  const offerings = await purchasesInstance.getOfferings();
  console.log('Offerings fetched:', offerings);
  return offerings;
};

export const purchasePackage = async (pkg) => {
  if (!purchasesInstance) throw new Error("Purchases instance is not initialized.");
  console.log('Purchasing package:', pkg);
  const result = await purchasesInstance.purchasePackage(pkg);
  console.log('Purchase result:', result);
  return result;
};
