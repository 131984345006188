import React, { useEffect, useState } from 'react';
import Login from './Login';
import Home from './Home';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebaseConfig';  // Ensure this import matches the export in firebaseConfig.js

const App = () => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        // Listen for authentication state to change.
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                console.log(user);
                setUser(user);
            } else {
                // User is signed out
                setUser(null);
            }
        });
        return () => unsubscribe();  // Cleanup subscription
    }, []);

    return (
        <div className="App">
            {user ? <Home user={user} onLogout={() => setUser(null)} /> : <Login onLogin={() => setUser(true)} />}
        </div>
    );
}

export default App;
