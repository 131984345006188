// Subscriptions.js
import React, { useEffect, useState } from 'react';
import { initializeRevenueCat, getOfferings, purchasePackage } from './RevenueCat';
import { auth } from './firebaseConfig'; // Adjust this import based on your firebase configuration
import './Subscription.css';
import SendData from './SendData';

const Subscriptions = () => {
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const configureRevenueCatAndFetchPackages = async () => {
      const user = auth.currentUser;
      if (!user) {
        setError('User not authenticated');
        setLoading(false);
        return;
      }

      const firebaseuid = user.uid;

      try {
        console.log('Initializing RevenueCat with firebaseuid:', firebaseuid);
        await initializeRevenueCat(firebaseuid);

        console.log('Fetching offerings...');
        const offerings = await getOfferings();
        console.log('Offerings fetched:', offerings);

        if (offerings && offerings.current && offerings.current.availablePackages) {
          setPackages(offerings.current.availablePackages);
        } else {
          setError('No offerings available');
        }
      } catch (err) {
        console.error('Error fetching offerings:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    configureRevenueCatAndFetchPackages();
  }, []);

  const handlePurchase = async (pkg) => {
    try {
      console.log('Purchasing package:', pkg);
      const { customerInfo } = await purchasePackage(pkg);
      if (customerInfo && customerInfo.entitlements && customerInfo.entitlements.active) {
        if (Object.keys(customerInfo.entitlements.active).includes('subscription')) {
          alert('Purchase successful!');
        }
      }
    } catch (e) {
      if (e.userCancelled) {
        alert('Purchase cancelled');
      } else {
        console.error('Purchase failed:', e);
        alert(`Purchase failed: ${e.message}`);
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="subscription-container">
          <SendData />

      {packages.map((pkg) => (
        <div className="subscription-box" key={pkg.identifier} onClick={() => handlePurchase(pkg)}>
          <h3>{pkg.rcBillingProduct.displayName}</h3>
          <p>{pkg.rcBillingProduct.currentPrice.formattedPrice}</p>
        </div>
      ))}
    </div>
  );
};

export default Subscriptions;
