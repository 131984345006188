import React, { useState } from 'react';
import axios from 'axios';
import { auth } from './firebaseConfig'; // Ensure you have Firebase initialized in this module
import './SendData.css'; // Import the CSS file for SendData component

const SendData = () => {
  const [inputText, setInputText] = useState('');
  const [responseMessage, setResponseMessage] = useState(null); // State to store the response message

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;
    if (!user) {
      setResponseMessage('No user is logged in');
      return;
    }

    try {
      const response = await axios.post('https://uz5kbzzpt9.execute-api.eu-north-1.amazonaws.com/Alpha/presaleverify', {
        firebaseuid: user.uid,
        redeemcode: inputText
      });
      setResponseMessage(response.data.message); // Set the response message from API
    } catch (error) {
      console.error('Failed to send data:', error);
      setResponseMessage(error.response?.data?.message || 'Failed to send data'); // Set the error message from API or a default message
    }
  };

  return (
    <div className="send-data-container">
      <h2>Verify Your Presale Code</h2>
      <form onSubmit={handleSubmit} className="send-data-form">
        <input
          type="text"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          placeholder="Enter your code"
          className="send-data-input"
        />
        <button type="submit" className="send-data-button">Submit</button>
      </form>
      {responseMessage && <p className="response-message">{responseMessage}</p>} {/* Display the response message */}
    </div>
  );
};

export default SendData;
